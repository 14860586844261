.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cs-container {
  margin: 100px 185px;
  min-height: 60vh;
}

@media (max-width: 1000px) {
  .cs-container {
    margin: 100px 80px;
  }

  .MuiGrid-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .cs-container {
    margin: 40px 20px;
  }

  body .MuiGrid-spacing-xs-10 {
    width: 100%;
    margin: 0;
  }

  body .MuiCard-root {
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(50% - 5px);
    height: auto;
  }

  body .MuiCard-root:nth-child(2n) {
    margin-right: 0px;
  }

  body .MuiCard-root:last-child {
    margin-bottom: 0;
  }

  body .makeStyles-media-3 {
    width: 60px;
    height: 60px;
  }

  body .MuiCardContent-root {
    padding: 10px;
  }

  body .MuiTypography-h5 {
    font-size: 1.2em;
  }

  body header {
    padding: 0 !important;
  }

  body .MuiButton-root {
    font-size: 0.7rem;
  }
}

body .MuiStepIcon-root.MuiStepIcon-completed {
  color: #31a000;
}

.makeStyles-card-70, .MuiPaper-elevation1 {
  cursor: pointer;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #32a101 !important;
}

.material-types__list {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.material-types__item {
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 12px;
  padding: 20px 12px;
  gap: 10px;
}

.material-types__item[data-disabled=true] {
  pointer-events: none;
  opacity: 0.5;
}

.material-types__item:hover {
  background-color: #eee;
}

@media (min-width: 768px) {
  .MuiDialogContent-root {
    min-width: 550px;
  }
}

.material-link {
  cursor: pointer;
  color: #333;
}

ol, li {
  color: #D23243;
}

a {
  cursor: pointer;
}

[data-disabled="true"] {
  opacity: 0.5 !important;
}

.scoalaSQ[data-checked="false"] svg,
.scoalaTQ[data-checked="false"] svg {
  display: none;
}

button.Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgb(229 229 229) !important;
}

body .MuiStepLabel-label {
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-weight: 700;
  cursor: pointer;
}

body .MuiStepLabel-label.MuiStepLabel-active {
  font-weight: 700;
}

body .MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: 700;
}

body .MuiStepLabel-label button {
  width: 180px;
  font-size: 0.75rem;
  padding: 5px 12px;
}